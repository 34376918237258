import React from "react";
import "./roi.css";
import lines from "../../assets/ROI/lines.svg";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
import NumberDisplay from "../NumberDisplay";
export default function ROI() {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });
  return (
    <section
      className={`roi-section ${isVisible ? "section-animation" : ""}`}
      ref={ref}
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-heading">
            <div className="heading-description">
              <h2>AgentInstruct ROI</h2>
            </div>
          </div>
          <div className="roi-content-wrapper">
            <div className="roi-card efficiency">
              <NumberDisplay
                first={1}
                last={45}
                char="%"
                isVisible={isVisible}
              />
              <h3>Increase in Operational Efficiency</h3>
              <img src={lines} alt="lines" />
            </div>
            <div className="roi-card reduction">
              <NumberDisplay
                first={100}
                last={70}
                char="%"
                isVisible={isVisible}
              />

              <h3>
                Reduction in Data <br /> Errors{" "}
              </h3>
              <img src={lines} alt="lines" />
            </div>

            <div className="roi-card timely-resolution">
              <NumberDisplay
                first={36}
                last={80}
                char="%"
                isVisible={isVisible}
              />
              <h3> Timely Resolution of Data Issues </h3>
              <img src={lines} alt="lines" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
