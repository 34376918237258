import React, { useState } from "react";
import "./UseCases.css";
import realTimeValidation from "../../assets/UseCasesSection/Data Quality Assurance/real-time-validation.jpg";
import dataProfiling from "../../assets/UseCasesSection/Data Quality Assurance/data-profiling.jpg";
import automaticCleansing from "../../assets/UseCasesSection/Data Quality Assurance/automatic-cleansing.jpg";
import dataDeduplication from "../../assets/UseCasesSection/Master Data Management/data-deduplication.jpg";
import dataSynchronization from "../../assets/UseCasesSection/Master Data Management/data-synchronization.jpg";
import entityResolution from "../../assets/UseCasesSection/Master Data Management/entity-resolution.jpg";
import aiDrivenAudits from "../../assets/UseCasesSection/Data Auditing and Reporting/ai-driven-audits.jpg";
import complianceMonitoring from "../../assets/UseCasesSection/Data Auditing and Reporting/ai-driven-audits.jpg";
import customAuditTrails from "../../assets/UseCasesSection/Data Auditing and Reporting/custom-audit-trails.jpg";
import advancedReporting from "../../assets/UseCasesSection/Business Intelligence/advanced-reporting.jpg";
import businessIntelligence from "../../assets/UseCasesSection/Business Intelligence/business-intelligence.jpg";
import predictiveAnalytics from "../../assets/UseCasesSection/Business Intelligence/predictive-analytics.jpg";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
const UseCases = () => {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });
  const [activeTab, setActiveTab] = useState(1);
  const handleTabSwitch = (index) => {
    setActiveTab(index);
  };
  return (
    <section
      className={`use-cases-section ${isVisible ? "section-animation" : ""}`}
      ref={ref}
      id="three"
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-heading">
            <div className="heading-tag">
              <span>USE CASES</span>
            </div>
            <div className="heading-description">
              <h2>Real-World Use cases of AgentInstruct </h2>
              <p>
                AgentInstruct offers scalable, Agentic AI -powered solutions
                that are transforming the way organizations manage, govern, and
                optimize their data workflows. These use cases demonstrate its
                impact across diverse industries:
              </p>
            </div>
          </div>
          <div className="second-tab-switch-wrapper">
            <div className="tab-links-wrapper">
              <div
                className={`${activeTab === 1 ? "active" : ""}`}
                onClick={() => handleTabSwitch(1)}
              >
                <p>Data Quality Assurance </p>
              </div>
              <div
                className={`${activeTab === 2 ? "active" : ""}`}
                onClick={() => handleTabSwitch(2)}
              >
                <p>Master Data Management </p>
              </div>
              <div
                className={`${activeTab === 3 ? "active" : ""}`}
                onClick={() => handleTabSwitch(3)}
              >
                <p>Data Auditing and Reporting </p>
              </div>
              <div
                className={`${activeTab === 4 ? "active" : ""}`}
                onClick={() => handleTabSwitch(4)}
              >
                <p>Business Intelligence </p>
              </div>
            </div>
            <div className="second-tab-switch-body">
              <div
                className={`second-tab-switch-content ${
                  activeTab === 1 ? "active" : ""
                }`}
              >
                <div className="second-tab-switch-cards">
                  <div className="tab-switch-card first-card">
                    <div className="card-text">
                      <h3>Real-Time Validation</h3>
                      <p>
                        AgentInstruct's AI agents scan and correct data issues
                        instantly.
                      </p>
                    </div>
                    <div className="card-image">
                      <img
                        src={realTimeValidation}
                        alt="tab-switch-card-image"
                      />
                    </div>
                  </div>
                  <div className="tab-switch-card second-card">
                    <div className="card-text">
                      <h3>Automatic Cleansing </h3>
                      <p>
                        AI agents remove duplicates, fix formatting, and
                        standardize data seamlessly.
                      </p>
                    </div>
                    <div className="card-image">
                      <img
                        src={automaticCleansing}
                        alt="tab-switch-card-image"
                      />
                    </div>
                  </div>
                  <div className="tab-switch-card third-card">
                    <div className="card-text">
                      <h3>Data Profiling</h3>
                      <p>
                        Continuous profiling delivers real-time insights into
                        data integrity.
                      </p>
                    </div>
                    <div className="card-image">
                      <img src={dataProfiling} alt="tab-switch-card-image" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`second-tab-switch-content ${
                  activeTab === 2 ? "active" : ""
                }`}
              >
                <div className="second-tab-switch-cards">
                  <div className="tab-switch-card first-card">
                    <div className="card-text">
                      <h3>Data Deduplication</h3>
                      <p>
                        AI agents merge duplicates, ensuring data consistency
                        across all sources.
                      </p>
                    </div>
                    <div className="card-image">
                      <img
                        src={dataDeduplication}
                        alt="tab-switch-card-image"
                      />
                    </div>
                  </div>
                  <div className="tab-switch-card second-card">
                    <div className="card-text">
                      <h3>Entity Resolution</h3>
                      <p>
                        Consolidate multiple records into unified, accurate
                        profiles with Agentic AI.
                      </p>
                    </div>
                    <div className="card-image">
                      <img src={entityResolution} alt="tab-switch-card-image" />
                    </div>
                  </div>
                  <div className="tab-switch-card third-card">
                    <div className="card-text">
                      <h3>Data Synchronization </h3>
                      <p>
                        Synchronize master data across systems, maintaining
                        consistency and accuracy.
                      </p>
                    </div>
                    <div className="card-image">
                      <img
                        src={dataSynchronization}
                        alt="tab-switch-card-image"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`second-tab-switch-content ${
                  activeTab === 3 ? "active" : ""
                }`}
              >
                <div className="second-tab-switch-cards">
                  <div className="tab-switch-card first-card">
                    <div className="card-text">
                      <h3>AI-Driven Audits</h3>
                      <p>
                        Automate data audits for accuracy and compliance with
                        Agentic AI.
                      </p>
                    </div>
                    <div className="card-image">
                      <img src={aiDrivenAudits} alt="tab-switch-card-image" />
                    </div>
                  </div>
                  <div className="tab-switch-card second-card">
                    <div className="card-text">
                      <h3>Custom Audit Trails</h3>
                      <p>
                        Create detailed, customizable reports tracking data
                        usage and changes.
                      </p>
                    </div>
                    <div className="card-image">
                      <img
                        src={customAuditTrails}
                        alt="tab-switch-card-image"
                      />
                    </div>
                  </div>
                  <div className="tab-switch-card third-card">
                    <div className="card-text">
                      <h3>Compliance Monitoring</h3>
                      <p>
                        Continuous monitoring of compliance with internal and
                        external regulations.
                      </p>
                    </div>
                    <div className="card-image">
                      <img
                        src={complianceMonitoring}
                        alt="tab-switch-card-image"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`second-tab-switch-content ${
                  activeTab === 4 ? "active" : ""
                }`}
              >
                <div className="second-tab-switch-cards">
                  <div className="tab-switch-card first-card">
                    <div className="card-text">
                      <h3>Business Intelligence </h3>
                      <p>
                        Data Consolidation: Gather and process data from
                        multiple sources for a unified view.
                      </p>
                    </div>
                    <div className="card-image">
                      <img
                        src={businessIntelligence}
                        alt="tab-switch-card-image"
                      />
                    </div>
                  </div>
                  <div className="tab-switch-card second-card">
                    <div className="card-text">
                      <h3>Advanced Reporting</h3>
                      <p>
                        Real-time dashboards provide actionable insights for
                        decision-makers.
                      </p>
                    </div>
                    <div className="card-image">
                      <img
                        src={advancedReporting}
                        alt="tab-switch-card-image"
                      />
                    </div>
                  </div>
                  <div className="tab-switch-card third-card">
                    <div className="card-text">
                      <h3>Predictive Analytics</h3>
                      <p>
                        Use Agentic AI to forecast trends and optimize business
                        strategies.
                      </p>
                    </div>
                    <div className="card-image">
                      <img
                        src={predictiveAnalytics}
                        alt="tab-switch-card-image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UseCases;
