import React from "react";
import "./BenefitsSection.css";
import flawLessDataQuality from "../../assets/BenefitsSection/flawless-data-quality.png";
import operationalEfficiency from "../../assets/BenefitsSection/operational-efficiency.png";
import trustInData from "../../assets/BenefitsSection/trust-in-data.png";
import smarterFasterDecisions from "../../assets/BenefitsSection/smarter-faster-decisions.png";
import dataGovernance from "../../assets/BenefitsSection/data-governance.png";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";

const BenefitsSection = () => {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });
  return (
    <section
      className={`benefits-section ${isVisible ? "section-animation" : ""}`}
      ref={ref}
      id="two"
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-heading">
            <div className="heading-tag">
              <span>BENEFITS</span>
            </div>
            <div className="heading-description">
              <h2>Key Benefits of Using AgentInstruct</h2>
              <p>
                Leverage AI agents to enhance data quality, governance,
                engineering, and overall efficiency.
              </p>
            </div>
          </div>
          <div className="benefits-cards-wrapper row">
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div className="benefits-card-inner benefits-first-card">
                <div className="benefits-first-card-text">
                  <h3>Achieve Flawless Data Quality </h3>
                  <p>
                    AI agents automatically detect and correct inconsistencies,
                    ensuring your data is always accurate and reliable
                  </p>
                </div>
                <div className="benefits-first-card-image">
                  <img src={flawLessDataQuality} alt="benefit-card-image" />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div className="benefits-card-inner benefits-second-card">
                <h3>Streamline Data Governance Effortlessly </h3>
                <p>
                  Let AI agents handle your governance tasks, ensuring full
                  compliance and eliminating manual errors
                </p>
                <img src={dataGovernance} alt="benefit-card-image" />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div className="benefits-card-inner benefits-third-card">
                <h3>Build Unshakable Trust in Data </h3>
                <p>
                  AI-driven processes ensure high-quality, trusted data that
                  teams can confidently rely on for critical decisions
                </p>
                <img src={trustInData} alt="benefit-card-image" />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8">
              <div className="benefits-card-inner benefits-fourth-card">
                <div className="benefits-fourth-card-text">
                  <h3>Make Smarter, Faster Decisions </h3>
                  <p>
                    Empower your business with real-time, AI-powered insights
                    that drive better, more informed decision-making
                  </p>
                </div>
                <div className="benefits-fourth-card-image">
                  <img src={smarterFasterDecisions} alt="benefit-card-image" />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="benefits-card-inner benefits-fifth-card">
                <h3>Boost Operational Efficiency </h3>
                <p>
                  Automate time-consuming data management tasks, allowing your
                  team to focus on high-value work, leading to increased
                  productivity.
                </p>
                <img src={operationalEfficiency} alt="benefit-card-image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BenefitsSection;
