import React from "react";
import "./Features.css";
import featureIcon1 from "../../assets/FeatureSection/feature-icon-1.svg";
import featureIcon2 from "../../assets/FeatureSection/feature-icon-2.svg";
import featureIcon3 from "../../assets/FeatureSection/feature-icon-3.svg";
import featureIcon4 from "../../assets/FeatureSection/feature-icon-4.svg";
import featureIcon5 from "../../assets/FeatureSection/feature-icon-5.svg";
import featureIcon6 from "../../assets/FeatureSection/feature-icon-6.svg";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";

const FeatureCard = ({ title, description, icon }) => {
  return (
    <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 feature-card">
      <div className="feature-card-inner">
        <div className="feature-icon">
          <img src={icon} alt="feature-icon" />
        </div>
        <div className="feature-text">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default function Features() {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });

  return (
    <section
      className={`features-section ${isVisible ? "section-animation" : ""}`}
      ref={ref}
      id="four"
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-heading">
            <div className="heading-tag">
              <span>FEATURES</span>
            </div>
            <div className="heading-description">
              <h2>Core Features of AgentInstruct </h2>
              <p>
                AgentInstruct delivers a suite of Agentic AI-driven features
                that automate and optimize data management. From data
                orchestration to privacy controls, these capabilities ensure
                efficiency, accuracy, and security throughout your data
                workflows.
              </p>
            </div>
          </div>
          <div className="features-cards-wrapper row">
            <FeatureCard
              title="AI-Powered Data Orchestration "
              description="AgentInstruct seamlessly orchestrates data flow across systems, ensuring efficiency and eliminating bottlenecks. "
              icon={featureIcon1}
            />
            <FeatureCard
              title="Intelligent Data Cataloging "
              description="Automatically tags and organizes datasets, improving data discoverability and reducing manual search efforts. "
              icon={featureIcon2}
            />
            <FeatureCard
              title="Adaptive Learning for Data Optimization "
              description="AgentInstruct learns from data patterns, optimizing workflows and continuously improving data accuracy. "
              icon={featureIcon3}
            />
            <FeatureCard
              title="Customizable Workflow Automation "
              description="Configure AI agents to automate specific workflows, enhancing flexibility and scalability in data management. "
              icon={featureIcon4}
            />
            <FeatureCard
              title="Self-Healing Data Pipelines "
              description="AI agents detect and fix pipeline failures autonomously, ensuring uninterrupted data flow and reducing downtime. "
              icon={featureIcon5}
            />
            <FeatureCard
              title="Multi-Layered Data Privacy Controls "
              description="Enforces role-based access, encryption, and anonymization to secure sensitive data and ensure compliance. "
              icon={featureIcon6}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
