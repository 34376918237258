import React from "react";
import "./secondbanner.css";
import secondBannerImg from "../../assets/SecondBanner/second-banner-image.svg";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
import config from "../../config";
export default function SecondBanner() {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });

  return (
    <section
      className={`second-banner-section ${
        isVisible ? "section-animation" : ""
      }`}
      ref={ref}
    >
      <div className="container">
        <div className="second-banner-section-wrapper">
          <div className="second-banner-section-heading">
            <h2>Ready to Transform Your Data Workflows? </h2>
            <p>
              Discover how AgentInstruct’s AI-powered agents can automate and
              optimize your data management processes. Get started today and
              unlock the full potential of your data operations!
            </p>
            <div className="second-banner-button-wrapper">
              <a href={config.bookdemo.Link}>
                <button className="second-banner-button">
                  <p>Book Demo</p>
                </button>
              </a>
            </div>
          </div>
          <div className="second-banner-image">
            <img src={secondBannerImg} alt="second-banner-image" />
          </div>
        </div>
      </div>
    </section>
  );
}
