import React, { useState } from "react";
import "./Solution.css";
import complianceMonitoring from "../../assets/SolutionSection/compliance-monitoring.png";
import dataConsistency from "../../assets/SolutionSection/data-consistency.png";
import operationsEffortlessly from "../../assets/SolutionSection/operations-effortlessly.png";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
export default function SolutionSection() {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });
  const [activeTab, setActiveTab] = useState(1);
  const handleTabSwitch = (index) => {
    setActiveTab(index);
  };
  return (
    <section
      className={`solution-section ${isVisible ? "section-animation" : ""}`}
      ref={ref}
      id="one"
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-heading">
            <div className="heading-tag">
              <span>SOLUTIONS</span>
            </div>
            <div className="heading-description">
              <h2>AI Agents for Data Quality and Governance </h2>
              <p>
                Our Agentic AI -driven solutions empower your organization to
                streamline data engineering tasks, improve governance, and
                automate quality management. Leverage AI agents to ensure data
                accuracy, compliance, and scalability across all your
                operations.
              </p>
            </div>
          </div>
          <div className="first-tab-switch-wrapper">
            <div className="first-tab-switch-body">
              <div
                className={`first-tab-switch-content ${
                  activeTab === 1 ? "active" : ""
                }`}
              >
                <div className="tab-switch-inner">
                  <div className="tab-switch-image">
                    <img src={complianceMonitoring} alt="solutions-image" />
                  </div>
                  <div className="tab-switch-text">
                    <h3>Optimize Data Quality with AI Agents </h3>
                    <p>
                      AgentInstruct provides real-time AI-driven feedback to fix
                      data quality issues swiftly.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={`first-tab-switch-content ${
                  activeTab === 2 ? "active" : ""
                }`}
              >
                <div className="tab-switch-inner">
                  <div className="tab-switch-image">
                    <img src={dataConsistency} alt="solutions-image" />
                  </div>
                  <div className="tab-switch-text">
                    <h3>Automate Governance and Compliance </h3>
                    <p>
                      Leverage AgentInstruct’s intelligent agents to ensure
                      seamless governance and compliance.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={`first-tab-switch-content ${
                  activeTab === 3 ? "active" : ""
                }`}
              >
                <div className="tab-switch-inner">
                  <div className="tab-switch-image">
                    <img src={operationsEffortlessly} alt="solutions-image" />
                  </div>
                  <div className="tab-switch-text">
                    <h3>Enhance Data Engineering </h3>
                    <p>
                      Streamline engineering workflows using AgentInstruct’s
                      AI-powered automation for faster processing.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-links-container">
              <div
                className={`${activeTab === 1 ? "active" : ""}`}
                onClick={() => handleTabSwitch(1)}
              >
                <h4>Maintain Data Consistency </h4>
                <p>AI agents ensure consistent data across systems.</p>
              </div>
              <div
                className={`${activeTab === 2 ? "active" : ""}`}
                onClick={() => handleTabSwitch(2)}
              >
                <h4>Continuous Compliance Monitoring </h4>
                <p>AI agents monitor regulations in real time.</p>
              </div>
              <div
                className={`${activeTab === 3 ? "active" : ""}`}
                onClick={() => handleTabSwitch(3)}
              >
                <h4>Scale Operations Effortlessly </h4>
                <p>AI agents enable scalable, automated operations.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
