import React, { useState, useRef, useEffect } from "react";
import "./banner.css";
import "./header.css";
import cardsImage from "../../assets/BannerSection/bannerimg.svg";
import agentInstructLogo from "../../assets/BannerSection/agent-instruct-logo.svg";
import bannerIcon1 from "../../assets/BannerSection/banner-card-1.svg";
import bannerIcon2 from "../../assets/BannerSection/banner-card-2.svg";
import bannerIcon3 from "../../assets/BannerSection/banner-card-3.svg";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
import config from "../../config";
import ImagePreloader from "../customHooks/ImagePreloader";
const Banner = () => {
  const { loadedImage, isLoading } = ImagePreloader(cardsImage);
  const menuRef = useRef(null);
  const hamburgerRef = useRef(null);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isHeaderActive, setIsHeaderActive] = useState(false);
  const navLinks = useRef([]);
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });
  const [ids] = useState({
    Solutions: "one",
    Benefits: "two",
    "Use cases": "three",
    Features: "four",
  });
  const scrollToSection = (id) => {
    const headerOffset = 100; // Adjust for fixed header height
    const sectionElement = document.getElementById(id);
    const elementPosition = sectionElement.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const link = navLinks.current.find(
            (link) => link.dataset.target === entry.target.id
          );
          if (entry.isIntersecting) {
            link.classList.add("redirection-active");
          } else {
            link.classList.remove("redirection-active");
          }
        });
      },
      { threshold: [0.4] }
    );

    navLinks.current.forEach((link) => {
      if (!link) return;
      const sectionId = link.dataset.target;
      const sectionElement = document.getElementById(sectionId);
      observer.observe(sectionElement);
    });

    return () => {
      observer.disconnect();
    };
  }, []);
  const handleHamburgerClick = () => {
    setIsMenuActive(!isMenuActive);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      hamburgerRef.current &&
      !hamburgerRef.current.contains(event.target)
    ) {
      setIsMenuActive(false);
    }
  };

  useEffect(() => {
    if (isMenuActive) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuActive]);

  const handleScroll = () => {
    const diff = window.innerWidth < 1024 ? 20 : 90;
    setIsHeaderActive(window.scrollY > diff);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    handleScroll(); // To set initial state on mount
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1023) {
        const mobileHamburger = document.querySelector(".mobile-hamburger");
        const mainMenu = document.querySelector(".main-navigation__menu");

        if (mobileHamburger) {
          mobileHamburger.classList.remove("is-activeHamburger");
        }
        if (mainMenu) {
          mainMenu.classList.remove("main-navigation__menu-active");
        }
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={`banner-section ${isVisible ? "animate" : ""}`}
      ref={ref}
    >
      <div className="container">
        {/* Header */}
        <header
          className={`pricing-header header ${isHeaderActive ? "active" : ""}`}
        >
          <div className="container">
            <nav>
              <div className="elixir-logo" onClick={scrollToTop} >
                <img src={agentInstructLogo} alt="agent-instruct-logo" />
              </div>
              <ul className="headder-content" ref={menuRef}>
                {["Solutions", "Benefits", "Use cases", "Features"].map(
                  (item, index) => (
                    <li
                      key={index}
                      className="elixirdata-hover-element-header"
                      tabIndex="0"
                    >
                      <a
                        href={`#${ids[item]}`}
                        data-target={ids[item]}
                        onClick={(e) => {
                          e.preventDefault();
                          scrollToSection(ids[item]);
                        }}
                        ref={(el) => navLinks.current.push(el)}
                      >
                        <p className="platform">{item}</p>
                      </a>
                    </li>
                  )
                )}
              </ul>
            </nav>
            <div
              className={`mobile-navigation-header ${isHeaderActive ? "mobile-active" : ""
                }`}
            >
              <div className="mobile-logo-side">
                <a href="https://example.com">
                  <div className="elixir-logo">
                    <img src={agentInstructLogo} alt="agent-instruct-logo" />
                  </div>
                </a>
              </div>
              <div
                className={`mobile-hamburger ${isMenuActive ? "is-activeHamburger" : ""
                  }`}
                onClick={handleHamburgerClick}
                ref={hamburgerRef}
              >
                <div className="hamburger" id="hamburger-6">
                  <span className="line"></span>
                  <span className="line"></span>
                  <span className="line"></span>
                </div>
              </div>
            </div>
            <nav
              className={`main-navigation__menu ${isMenuActive ? "main-navigation__menu-active" : ""
                }`}
            >
              <div className="container">
                <ul id="redirection-links" ref={menuRef}>
                  {["Solutions", "Benefits", "Use cases", "Features"].map(
                    (item, index) => (
                      <li key={index} tabIndex="0">
                        <a
                          href={`#${ids[item]}`}
                          data-target={ids[item]}
                          onClick={(e) => {
                            e.preventDefault();
                            scrollToSection(ids[item]);
                            setIsMenuActive(false);
                          }}
                          ref={(el) => navLinks.current.push(el)}
                        >
                          <p>{item}</p>
                        </a>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </nav>
          </div>
        </header>
        {/* Banner Content */}
        <div className="banner-content-wrapper">
          <div className="left-side">
            <h1>Transform Your Data Operations with AgentInstruct </h1>
            <p>
              A comprehensive multi-agent workflow framework to enhance data
              quality—including synthetic data quality—optimize data management,
              and streamline data engineering processes.
            </p>
            <div className="banner-button-wrapper">
              <a href={config.bookdemo.Link}>
                <button className="banner-button">
                  <p>Book Demo</p>
                </button>
              </a>
            </div>
          </div>
          <div className="right-side">
            {isLoading ? (
              ""
            ) : (
              <img
                src={loadedImage}
                alt="Investigation, Alerts, and Recommendation"
                loading="eager"
                decoding="sync"
              />
            )}
          </div>
        </div>
        <div className="banner-cards-wrapper">
          <div className="banner-card banner-first-card">
            <img src={bannerIcon1} alt="banner-card-icon" />
            <h2>Struggling with Data Accuracy? </h2>
            <p>
              Businesses often face challenges with maintaining reliable data,
              especially when managing both synthetic and real-world datasets.
            </p>
          </div>
          <div className="banner-card banner-second-card">
            <img src={bannerIcon2} alt="banner-card-icon" />
            <h2>Is Manual Management Slowing You Down? </h2>
            <p>
              Handling data governance and compliance manually increases the
              risk of errors and inefficiencies.
            </p>
          </div>
          <div className="banner-card banner-third-card">
            <img src={bannerIcon3} alt="banner-card-icon" />
            <h2>Are Data Pipelines Slowing Progress? </h2>
            <p>
              Manual pipeline creation and maintenance take time, delaying
              real-time data processing and scalability.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
